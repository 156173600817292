import core from '@/admin/core';

function request(params: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  pathParams: object;
  body: object;
}) {
  return core.httpClient.post('/admin/public/unit04', params);
}

interface GetItemResponse<T> {
  item_id: string;
  versions: GetVersionResponse<T>[];
}

interface GetVersionResponse<T> {
  status: number;
  display_date: string;
  publish_date: string;
  data: T;
}

export class Item<T> {
  itemId: string;
  versions: GetVersionResponse<T>[];

  constructor(params: GetItemResponse<T>) {
    this.itemId = params.item_id;
    this.versions = params.versions;
  }

  get version () {
    return this.versions.find(version => version.status === 2);
  }
}

export async function getItems<T>(params: {
  content_id: string;
  limit: number;
  sort_type: number;
  properties: string;
}) {
  const result = await request({
    method: 'GET',
    url: '/v20200701/items',
    pathParams: params,
    body: {},
  });
  return (result.data as GetItemResponse<T>[]).map((item) => new Item<T>(item));
}
