import { ref, Ref } from '@vue/composition-api';
import { getItems, Item } from '@/admin/unit04';

if (typeof process.env.VUE_APP_TOPICS_CONTENTS_ID !== 'string') {
  throw new Error('requires VUE_APP_TOPICS_CONTENTS_ID');
}
const topicsContentsId = process.env.VUE_APP_TOPICS_CONTENTS_ID;

interface Topics {
  title: string;
  scope: string;
}

const topics: Ref<Item<Topics>[]> = ref([]);
const isTopicsLoading = ref(false);

const getTopics = async () => {
  isTopicsLoading.value = true;
  const result = await getItems<Topics>({
    content_id: topicsContentsId,
    limit: 100,
    sort_type: 2,
    properties: 'title,scope',
  });
  topics.value = result;
  isTopicsLoading.value = false;
}

export const useTopics = () => {
  getTopics();

  return { topics, isTopicsLoading };
};
