




















import { defineComponent } from '@vue/composition-api';
import { useTopics } from '@/admin/topics';

export default defineComponent({
  name: 'Topics',
  setup() {
    const headers = [
      { text: 'タイトル', value: 'version.data.title' },
      { text: '公開範囲', value: 'version.data.scope' },
      // { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    const { topics, isTopicsLoading } = useTopics();

    return { headers, topics, isTopicsLoading };
  },
});
